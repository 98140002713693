import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export interface GenericEditTableConfiguration {
  isResizeColumn: boolean;
  isClickRowEvent: boolean;
  isEditIcon: boolean;
  isDeleteIcon: boolean;
}

export interface GenericEditTableHeader {
  field: string;
  subField?: string;
  label: string;
  templateName: TemplateRef<any>;
  templateIcon?: TemplateRef<any>;
  isLink?: boolean;
  isBadge?: boolean;
  isCustomTooltipDescription?: boolean;
  disableTooltip?: boolean;
  behindSchedule?: boolean;
  isColor?: boolean;
  isEditable?: boolean;
  isRequired: boolean;
  typeInput: 'text' | 'select'
  nextFocusInput?: string,
  dropdownConfig?: {
    dropdownOptions?: { label: string, value: any }[],
    dropdownPlaceHolder?: string
    dropdownIconNewRow?: string,
    dropdownNoContentMessage?: string,
  },
  inputTextConfig?: {
    maxLegth?: number,
    isTooltip?: boolean,
  },
  insertRowConfig?: {
    icon: string,
    placeholder: string,
  }
}
@Component({
  selector: 'app-generic-edit-table',
  templateUrl: './generic-edit-table.component.html',
  styleUrl: './generic-edit-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericEditTableComponent implements AfterViewInit {

  @Input() dataConfiguration: GenericEditTableConfiguration = { isResizeColumn: false, isClickRowEvent: false, isEditIcon: false, isDeleteIcon: false };
  @Input() dataHeaders: GenericEditTableHeader[] = [];
  @Input() dataList: any[];


  @Output() loadMoreSelect: EventEmitter<{ targetLabel: string }> = new EventEmitter<{ targetLabel: string }>();
  @Output() changeRow: EventEmitter<{nextFocusInput: string | null, data: any}> = new EventEmitter<{nextFocusInput: string | null, data: any}>();
  @Output() deleteRow: EventEmitter<any> = new EventEmitter<any>();

  isLoading = false;
  
  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    const index = this.dataList.findIndex(item => item.id === 0);
    if (index !== -1) {
      const [item] = this.dataList.splice(index, 1);
      this.dataList.push(item);
    }

    const tableElement = document.querySelector('.outer__wrapper');
    if (tableElement) {
      tableElement.addEventListener('scroll', () => {
        document.body.click();
      });
    }
  }

  // # INPUT TEXTO
  onCreateOrEditRowInputText(event: any, rowData: any, nextFocusInput?: string): void {
    this.emitChangeRow(nextFocusInput, rowData);
  }

  onKeyDownInputText(keyboardEvent: KeyboardEvent, rowData: any, nextFocusInput?: string): void { 
    if (keyboardEvent.key === 'Enter' || keyboardEvent.key === 'Tab' || keyboardEvent.key === 'Escape') {
      this.emitChangeRow(nextFocusInput, rowData);
    } 
  }

  onModelChangeInputText(event: any, rowData: any, isRequired: boolean, maxLength: any): void { 
    if (isRequired && !event) {
      rowData._isValidInputText = false;
      return;
    } 
    rowData._isValidInputText = true;
    
    if (isRequired && event && event.length > maxLength) {
      rowData._isValidInputText = false;
    } else {
      rowData._isValidInputText = true;
    }
  }


  // # INPUT SELECT

  onKeyDownInputSelect(keyboardEvent: KeyboardEvent, rowData: any, setNextFocus: string): void { }
  onSelectedInputSelect(event: any) { }
  onModelChangeInputSelect(event: any, rowData: any, isRequired: boolean, nextFocusInput?: string): void { 
    if (isRequired && !event) {
      rowData._isValidSelect = false;
      return;
    } else {
      rowData._isValidSelect = true;
    }
    this.emitChangeRow(nextFocusInput, rowData);
  }

  onloadMoreSelect(targetSelect: string): void {
    this.isLoading = false;
    this.loadMoreSelect.emit({ targetLabel: targetSelect });
  }

  resizeSelectOptionsOverlay(event: any): void {
    const elemento = document.getElementById('idSelectGenericEditableTable');
    if (elemento) {
      const observer = new MutationObserver(() => {
        const largura = elemento.offsetWidth;
        const primeiroElemento: any = document.querySelector('.cdk-overlay-pane');
        if (primeiroElemento) {
          primeiroElemento.style.width = `${largura}px`;
          this.cdr.detectChanges();
        }
      });
      observer.observe(elemento, { attributes: true, childList: true, subtree: true });
    } 
  }

  deleteRowData(rowData: any): void {
    if (rowData.id !== 0) {
      this.deleteRow.emit(rowData);
    }
  }


  private emitChangeRow(nextFocusInput: string | undefined | null, rowData: any): void {
    this.changeRow.emit({
      nextFocusInput: nextFocusInput ? `${nextFocusInput}-${rowData.id}` : null,
      data: rowData
    });
  }
}
