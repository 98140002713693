import {Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core'
import { AuthService } from './core/auth/service/auth.service';
import {TranslateService} from "@ngx-translate/core";
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NzIconService } from 'ng-zorro-antd/icon';
import {PrimeNGConfig} from "primeng/api";
import {Subscription} from "rxjs";
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { MyStudiesConsultancyModalComponent } from './modules-externals/consultancy/modal/my-studies-consultancy/my-studies-consultancy.component';
import { UserService } from './shared/services/user.service';
registerLocaleData(localePt, 'pt-BR');
const customPtLocale = {
  ...localePt,
  monthsShort: [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ]
};
registerLocaleData(customPtLocale, 'pt-BR-custom');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  sl_dashboard_icon =
  '<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" id="dashboards-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 0C3.55228 0 4 0.447715 4 1V16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H4V19C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19V18H1C0.447715 18 0 17.5523 0 17C0 16.4477 0.447715 16 1 16H2V1C2 0.447715 2.44772 0 3 0ZM7 4C7.55228 4 8 4.44772 8 5V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V5C6 4.44772 6.44772 4 7 4ZM15 6C15.5523 6 16 6.44772 16 7V13C16 13.5523 15.5523 14 15 14C14.4477 14 14 13.5523 14 13V7C14 6.44772 14.4477 6 15 6ZM11 8C11.5523 8 12 8.44772 12 9V13C12 13.5523 11.5523 14 11 14C10.4477 14 10 13.5523 10 13V9C10 8.44772 10.4477 8 11 8Z" /></svg>';

  title = 'angular-view-smart-licensing';

  menuFull = true;

  subscription: Subscription;

  isUserConsultancy: boolean = false
  userConsultancyName: string = ''
  constructor(private readonly auth: AuthService,
              private translateService: TranslateService,
              private iconService: NzIconService,
              private config: PrimeNGConfig,
              private modal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private router: Router,
              private _userService: UserService
            ){
    translateService.addLangs(['pt', 'en']);
    translateService.setDefaultLang('pt');
    translateService.use('pt');
    this.iconService.addIconLiteral('ng-zorro:antd', this.sl_dashboard_icon);

    this.subscription = this.translateService.stream('primeng').subscribe(data => {
      this.config.setTranslation(data);
    });
  }


  async ngOnInit(): Promise<void> { 
    window.localStorage.setItem("sidebar ", 'false');  
    this.verifyIsFornecedor()
  }

  verifyIsFornecedor() {
    setTimeout(() => {
      this.isUserConsultancy = this._userService.getSessionRoleFornecedor('ROLE_FORNECEDOR_EQUIPE')
      const userNameSession = window.sessionStorage.getItem('loggedUser')
      this.userConsultancyName = userNameSession ? JSON.parse(userNameSession)['name'] : ''    
      if(this.isUserConsultancy && window.localStorage.getItem('CONSULTORES_BEM_VINDO_MODAL') !== 'S') this.showModalConsultancy()
    }, 500)
  }

  forceHideText() {
    window.localStorage.setItem("sidebar", this.menuFull.toString());
    this.menuFull = !this.menuFull;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showModalConsultancy() {
    const modal = this.modal.create<MyStudiesConsultancyModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzWidth: window.innerWidth - ((window.innerWidth)*5)/100,
      nzCentered: true,
      nzMaskClosable: false,
      nzModalType: 'default',
      nzKeyboard: false,
      nzContent: MyStudiesConsultancyModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.userName = this.userConsultancyName;
    instance.buttonAction = () => {
      this.router.navigate(['route']);
    }

  }

}
