<div class="app-modal-period-date">
    <i class="pi pi-filter ft__size12" [class.has__value]="hasFilterValue()" (click)="modalResponsibleUser.toggle($event)"></i>

    <p-overlayPanel #modalResponsibleUser>
        <div class="ant-table-filter-dropdown date__container__overlay">
            <div class="date__box">
                <div>
                    <p class="label__w700">Por data</p>
                    <p-calendar
                        *ngIf="isMonth"
                        [(ngModel)]="inputForDate"
                        [iconDisplay]="'input'"
                        [showIcon]="true"
                        placeholder="Selecione a data..."
                        inputId="inputForDateId"
                        view="month"
                        dateFormat="mm/yy"
                        (ngModelChange)="setMinDate()"/>
                    <p-calendar
                        *ngIf="!isMonth"
                        [(ngModel)]="inputForDate"
                        [iconDisplay]="'input'"
                        [showIcon]="true"
                        placeholder="Selecione a data..."
                        inputId="inputForDateId"
                        (ngModelChange)="setMinDate()"/>
                </div>

                <div class="mt__16">
                    <p class="label__w700">Por período</p>
                    <p-calendar
                        *ngIf="isMonth"
                        [(ngModel)]="inputForPeriod"
                        [iconDisplay]="'input'"
                        [minDate]="minDate"
                        [showIcon]="true"
                        view="month"
                        dateFormat="mm/yy"
                        placeholder="Selecione o período..."
                        inputId="inputForPeriodId"/>
                    <p-calendar
                        *ngIf="!isMonth"
                        [(ngModel)]="inputForPeriod"
                        [iconDisplay]="'input'"
                        [minDate]="minDate"
                        [showIcon]="true"
                        placeholder="Selecione o período..."
                        inputId="inputForPeriodId"/>
                </div>

                <div class="buttons__prediction-conclusion mt__16">
                    <app-button-icon-secondary
                        customClassBtn="padding: 0 20px; float: left;"
                        [id]="'btn-icon'"
                        text="Limpar filtro"
                        altText="Limpar filtro"
                        (click)="onClean()">
                    </app-button-icon-secondary>

                    <app-button-icon-primary
                        customClassBtn="padding: 0 20px; float: right;"
                        [id]="'btn-icon'"
                        text="Aplicar filtro"
                        altText="Aplicar filtro"
                        (click)="onApplyFilter()">
                    </app-button-icon-primary>
                </div>
            </div>
        </div>
    </p-overlayPanel>
</div>
