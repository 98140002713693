import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';

import { CommunicationsListComponent } from './communications-list/communications-list.component';
import { CommunicationsFormComponent } from './communications-form/communications-form.component';
import { AuthGuard } from 'src/app/core/auth/guards/auth.guard';

const routes: Routes = [
  { path: '', component: CommunicationsListComponent, canActivate: [AuthGuard] },
  { path: 'new', component: CommunicationsFormComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommunicationsRoutingModule { }
