import { RoutesEnum } from 'src/app/shared/enum/routes.enum';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './view/main/main.component';
import { ScopeChangeRequestComponent } from './view/scope-change-request/scope-change-request.component';
import { ScopeChangeAnalysisComponent } from './view/scope-change-analysis/scope-change-analysis.component';
import {ScopeChangeResolver} from "./resolver/scope-change.resolver";
import {
  ScopeChangeDisciplineAssessmentComponent
} from "./view/scope-change-discipline-assessment/scope-change-discipline-assessment.component";
import {ScopeChangeDisciplineResolver} from "./resolver/scope-change-discipline.resolver";
import { ScopeChangePublishFinalComponent } from './view/scope-change-publish-final/scope-change-publish-final.component';
import { ScopeChangeReviewComponent } from './view/scope-change-review/scope-change-review.component';
import { ScopeChangeReviewResolver } from './resolver/scope-change-review.resolver';
import { ScopeChangeAnalysisResolver } from './resolver/scope-change-analysis.resolver';
import { AuthGuard } from 'src/app/core/auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: RoutesEnum.SCOPE_CHANGE_NEW,
        component: ScopeChangeRequestComponent,
        canActivate: [AuthGuard],
        resolve: { scopeChangeData: ScopeChangeResolver},
      },
      {
        path: RoutesEnum.SCOPE_CHANGE_DETAIL_EDIT,
        component: ScopeChangeRequestComponent,
        canActivate: [AuthGuard],
        resolve: { scopeChangeData: ScopeChangeResolver}
      },
      {
        path: RoutesEnum.SCOPE_CHANGE_DETAIL,
        component: ScopeChangeAnalysisComponent,
        canActivate: [AuthGuard],
        resolve: { scopeChangeAnalysis: ScopeChangeAnalysisResolver }
      },
      {
        path: RoutesEnum.SCOPE_CHANGE_ASSESSMENT,
        component: ScopeChangeDisciplineAssessmentComponent,
        canActivate: [AuthGuard],
        resolve: { scopeChangeDiscipline: ScopeChangeDisciplineResolver }
      },
      {
        path: RoutesEnum.SCOPE_CHANGE_PUBLISH,
        canActivate: [AuthGuard],
        component: ScopeChangePublishFinalComponent
      },
      {
        path: RoutesEnum.SCOPE_CHANGE_REVIEW,
        component: ScopeChangeReviewComponent,
        canActivate: [AuthGuard],
        resolve: { scopeChangeReviewData: ScopeChangeReviewResolver},
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScopeChangeRoutingModule { }
