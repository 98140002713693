
export enum RoutesModulesExternalsEnum {
    // PARAMS
    ID = ':id',

    // BASE PATHS
    CONSULTANCY = 'consultores',

    // PAGES
    STUDIES = 'meus-estudos',
    
    // STUDIES
    MY_STUDIES = `${STUDIES}`,
    DELIVERED_STUDIES = `${STUDIES}/estudos-entregues`,
}