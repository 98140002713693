<div nz-row nzJustify="space-between" class="aling__center">

  <div nz-col class="topbar-logo">
    <img src="/assets/icons/logo_vale.svg" alt="logo vale" />
  </div>

  <div nz-col class="topbar-logo"> Smart Licensing </div>
  
  <div nz-col class="container-icons">
    <a id="btnProfile" (click)="userProfile()">
      <app-svg-icon icon="user_profile-icon" fill="#007e7a"></app-svg-icon>
    </a>
  </div>

</div>
