import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface EmitterOrderBy {
  status: boolean, 
  orderBy: string
}

@Component({
  selector: 'app-modal-order-by',
  templateUrl: './modal-order-by.component.html',
  styleUrl: './modal-order-by.component.scss'
})
export class ModalOrderByComponent {

  private _cleanValues: boolean = false;
  
  @Input()
  set cleanValues(value: boolean) {
    if (value !== this._cleanValues) {
      this._cleanValues = value;
      if (this._cleanValues) this.resetOrderBy();
    }
  };

  isOrderBy: boolean = false;
 
  @Output() changeValue: EventEmitter<EmitterOrderBy> = new EventEmitter<EmitterOrderBy>();

  orderBy(): void {
    this.isOrderBy = !this.isOrderBy;
    this.changeValue.emit({ status: this.isOrderBy, orderBy: this.isOrderBy ? 'DESC' : 'ASC' });
  }

  resetOrderBy(): void {
    this.isOrderBy = false;
  }
}
